<template>
  <div class="relative flex items-start">
    <div class="flex h-6 items-center">
      <input
        ref="input"
        :id="name"
        aria-describedby="comments-description"
        name="comments"
        type="checkbox"
        :class="[
          'h-4 w-4 rounded border-gray-300 focus:ring-indigo-600',
          disabled ? 'text-gray-400' : 'text-indigo-600',
        ]"
        :value="inputValue"
        :disabled="disabled"
        v-model="checked"
        v-on:change="handleChange"
      />
    </div>
    <div v-if="withLabel" class="ml-3 text-sm leading-6">
      <label
        :for="name"
        :class="getColor.label[color] ?? getColor.label['primary']"
        class="font-medium"
        >{{ label }}</label
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    value: null,
    name: {
      type: String,
      default: 'checkbox',
    },
    color: String,
    inputValue: null,
    disabled: Boolean,
    withLabel: {
      type: Boolean,
      default: true,
    },
    inputChecked: Boolean
  },
  emits: ['change', 'check', 'uncheck'],
  computed: {
    checked: {
      get: function () {
        return this.inputChecked || this.value;
      },
      set: function (value) {
        this.$emit('input', value);
      },
    },
    getColor() {
      const colors = {
        label: {
          primary: 'text-gray-900',
          secondary: 'text-gray-500',
        },
      };
      return colors;
    },
  },
  methods: {
    handleChange(e) {
      this.$emit(e.target.checked ? 'check' : 'uncheck');
      this.$emit('change');
    },
  },
};
</script>
